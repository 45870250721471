import React, { useEffect, useState } from "react"
import empowerRobot from "../images/empower-robot-image.png"
import QCGPrimaryLogo from "../images/QCG-Primary-logo-_1_.png"
import empowerBubble from "../images/Speech bubble mobine mockup.png"

import "./ISCWest2025Technician.css"

function DesignElements() {
  return (
    <>
      <div className="iscwest-design-element-wrapper"></div>
    </>
  )
}

function ISCWest2025Header() {
  return (
    <>
      <div className="iscwest-logo">
        <img
          src="https://cdn.sanity.io/images/55lcecww/production/bdb1134d978966f0a3528c7efafc532b47fe8d0f-1992x750.png"
          alt="ISC West 2025"
          id="iscwest-2025"
          className="w-full h-full"
          style={{
            width: "auto !important",
            height: "auto !important",
            maxWidth: "350px",
          }}
        />
      </div>
    </>
  )
}

function ISCWest2025Info() {
  return (
    <>
      <div className="iscwest-info">
        <div
          class="iscwest-bubble-container"
          style={{ transform: "translate(25px, -10px)" }}
        >
          <img
            src={empowerBubble}
            alt="ISC West Bubble"
            width={320}
            height={320}
            id="iscwest-bubble"
            style={{
              width: "100% !important",
            }}
          />
          <div className="iscwest-info-text">
            <h3>Hi, I'm Lenny!</h3>
            <p>
              I'm your Technical Support Agent, here to assist you with
              troubleshooting tips, best practices, and more. Ask away!
            </p>
          </div>
        </div>
        <div className="iscwest-info">
          <img
            src={empowerRobot}
            alt="ISC West Robot"
            width={220}
            height={220}
            id="iscwest-robot"
          />
        </div>
      </div>
    </>
  )
}

function ISCWest2025Embed() {
  const [embedHtml, setEmbedHtml] = useState(null)

  // useEffect(() => {
  //   fetch("https://embed.cody.bot/9e4c62ec-a222-483d-9e10-68c7c3f5240b")
  //     .then(response => response.text())
  //     .then(html => setEmbedHtml(html))
  //     .catch(error => console.error("Error loading Cody AI:", error))
  // }, [])

  return (
    <div
      style={{
        maxWidth: "350px",
        margin: "0 auto",
        maxHeight: "550px !important",
      }}
    >
      <iframe
        src="https://embed.cody.bot/9e78a3ca-18ef-4c1b-b680-78c567c12dfa"
        name="codyai"
        scrolling="no"
        frameborder="1"
        marginheight="0"
        marginwidth="0"
        height="550px"
        width="100%"
        allowfullscreen
        style={{ maxHeight: "550px !important" }}
      ></iframe>
    </div>
  )
}

function ISCWest2025Footer() {
  return (
    <>
      <div className="iscwest-footer">
        <h3>BROUGHT TO YOU BY</h3>
        <a href="/" className="footer-iscwest-logo">
          <img
            src={QCGPrimaryLogo}
            alt="QCG Primary Logo"
            width={2458}
            height={564}
          />
        </a>
      </div>
    </>
  )
}

function ISCWest2025() {
  return (
    <div className="iscwest-container">
      <DesignElements />
      <ISCWest2025Header />
      <ISCWest2025Info />
      <ISCWest2025Embed />
    </div>
  )
}

export default ISCWest2025
